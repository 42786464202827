@import 'styles/colors.scss';

.mainContainer{
    display: inline-block;

    @media screen and(max-width: 576px) {
        width: 100%;
    }
}

.paragraph{
    font-family: futura-pt, sans-serif;
}

.title{
    composes: paragraph;
    font-size: 18px;
    margin-bottom: 10px;
}

.input{
    composes: paragraph;
    font-size: 18px;
    border: 0;
    width: 370px;
    padding: 15px;

    @media screen and(max-width: 576px) {
        box-sizing: border-box;
        width: calc(100% - 55px);
    }
}

.input:focus{
    outline:none;
}

.icon{
    width: 25px;
    height: 25px;
    margin: 0px;
    padding: 15px;
    vertical-align: middle;
}

.form{
    background-color: $white;
    position: relative;

    width: 460px;

    @media screen and(max-width: 576px) {
        width: 100%;
    }
}

.dropdown{
    composes: paragraph;
    font-size: 18px;
    line-height: 28px;

    position: absolute;
    background-color: $white;
    border: 2px solid $cream;
    width: 430px;
    max-height: 300px;
    overflow-y: auto;
    margin-top: 5px;
    margin-left: -2px;
    padding: 15px;
    z-index: 5;

    @media screen and(max-width: 576px) {
        box-sizing: border-box;
        width: 100%;
    }
}

.highlight{
    background-color: $white;
    color: $tyrianPurple;
}

.unhighlight{
    color: $tyrianPurpleInactive;
}

.link{
    display: block;
    text-decoration: none;
}

.active{
    color: $tyrianPurple;
    background-color: $white;
}

.input::placeholder {
    color: $tyrianPurple;
    opacity: 0.5; /* Firefox */
}

.input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $tyrianPurple;
    opacity: 0.5;
}

.input::-ms-input-placeholder { /* Microsoft Edge */
    color: $tyrianPurple;
    opacity: 0.5;
}

/* width */
.dropdown::-webkit-scrollbar {
    width: 12px;
}

/* Track */
.dropdown::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px $cream;
    border: solid 3px transparent;
    border-radius: 5px;
}

/* Handle */
.dropdown::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px $tyrianPurple;
    border: solid 3px transparent;
    border-radius: 5px;
}

/* Handle on hover */
.dropdown::-webkit-scrollbar-thumb:hover {
    border-radius: 5px;
    box-shadow: inset 0 0 10px 10px $tyrianPurpleInactive;
    border: solid 3px transparent;
}
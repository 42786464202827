.p {
	font-family: futura-pt, sans-serif;
	font-size: 20px;
	text-align: justify;
}

.h2 {
	font-family: 'Playfair Display', serif;
	font-size: 40px;
}

.a, .a:focus, a:visited{
	font-weight: 500;
	text-decoration: none;
}

.li{
	@extend .p;
}

.ul, ol{
	padding-left: 20px;
}

.popular{
    @media screen and(max-width: 576px) {
        display: none;
    }
}

.popularMobile{
    display: none;

    @media screen and(max-width: 576px) {
        display: block;
    }
}

.littleHeader {
	font-family: 'Playfair Display', serif;
    font-weight: 500;
    font-size: 40px;
    line-height: 35px;
    margin: 15px 0;
}

.topicHeader{
    font-family: futura-pt, sans-serif;
    font-size: 24px;
    line-height: 35px;
    margin-top: 30px;
    margin-bottom: 10px;
    font-weight: 500;
}

.popularTitle{
    composes: topicHeader;
    margin: 0;
}

.popularTitleMobile{
    composes: topicHeader;
    margin: 0;
    margin-top: 30px;
}

.popularArrow{
    margin-left: 10px;
    width: 10px;
    height: 15px;
    vertical-align: middle;
}

.hideOnMobile{
    display: block;

    @media screen and(max-width: 576px) {
        display: none;
    }
}
@use "sass:math";
@import "styles/colors.scss";

.paragraph {
  font-family: futura-pt, sans-serif;
  font-size: 20px;
  line-height: 35px;
}

.littleHeader {
  font-family: "Playfair Display", serif;
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 25px;
}

.name {
  @extend .littleHeader;
  font-size: 28px;

  margin: 25px 0;

  @media screen and (max-width: 1024px) {
    text-align: center;
  }
}

.bio {
  @extend .paragraph;

  max-width: 285px;
  margin-bottom: 20px;

  @media screen and (max-width: 1024px) {
    margin: 0 auto;
    margin-bottom: 20px;
    text-align: center;
    max-width: 450px;
  }
}

.image {
  width: 160px;
  height: 160px;
  clip-path: circle(80px at center);
  display: block;

  @media screen and (max-width: 1024px) {
    margin: 0 auto;
  }
}

.overviewContainer {
  display: block;
  margin-bottom: 40px;
  text-decoration: none;
  transition: 0.1s;

  padding: 30px;
  box-sizing: border-box;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: $cream;

      .arrow {
        transition: 0.1s;
        opacity: 1;
      }

      .readMore {
        transition: 0.1s;
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 0px;
  }
}

.readMore {
  @extend .littleHeader;
  font-size: 24px;
  margin: 0;
  margin-top: 20px;
  opacity: 0.5;
  transition: 0.1s;

  @media screen and (max-width: 1024px) {
    opacity: 1;
  }
}

.arrow {
  opacity: 0.5;
  transition: 0.1s;
  vertical-align: middle;

  @media screen and (max-width: 1024px) {
    opacity: 1;
  }
}

.date {
  @extend .paragraph;
  opacity: 0.5;
  font-weight: 500;
  margin: 10px 0;
}

.overviewImage {
  height: 0;
  overflow: hidden;
  padding-top: math.div(720px, 1280px) * 100%;
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.loadMore {
  @extend .littleHeader;
  font-size: 24px;
  cursor: pointer;
  text-align: center;

  svg {
    width: 15px;
    height: 15px;
    vertical-align: middle;

    opacity: 0.5;

    @media screen and (max-width: 1024px) {
      opacity: 1;
    }
  }
}

.loadMore > * {
  display: inline-block;
  margin-right: 10px;
}

.loadMore:last-child {
  margin: 0;
}

@media (hover: hover) and (pointer: fine) {
  .loadMore:hover svg {
    opacity: 1;
  }
}

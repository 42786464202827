.topicHeader{
    font-family: futura-pt, sans-serif;
    font-size: 24px;
    line-height: 35px;
    margin-top: 30px;
    margin-bottom: 10px;
    font-weight: 500;
}

.topic{
    composes: topicHeader;
    font-size: 18px;
    margin: 10px 0;
    display: block;
    text-decoration: none;
}

.question{
    composes: topicHeader;
    font-size: 18px;
    display: block;
    margin: 10px 0;
    font-weight: normal;
    text-decoration: none;
}
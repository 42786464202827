.socials{
    @media screen and (max-width: 1024px) {
        text-align: center;
    }
}

.social:last-child{
    margin: 0;
}

.social{
    opacity: 0.25;
    transition: 0.1s;
    display: inline-block;
    margin-right: 30px;
    width: 30px;

    svg{
        width: 30px;
    }

    &:hover{
        opacity: 1;
    }

    @media screen and (max-width: 1024px) {
        margin-right: 30px;
    }
}
$white: #FFFFFF;
$tyrianPurple: #4A193D;
$tyrianPurpleInactive: #b398ab;
$tyrianPurpleHalfOpacity: #9f8595;
$cream: #F4F1ED;
$lightCream: #fdfcfb;
$thistle: #AA92A0;
$infoBg: #EDF3F4;
$infoText: #364648;
$infoButton: #3C5559;
$successBg: #a0d6ac;
$failureBg: #dbd1d8;
$info: #364648;
$whiteCream: #FCFCFB;
$darkBlue: #104a8e;

.container{
    display: flex;
    flex-wrap: wrap;    
    justify-content: space-evenly;
    margin: 100px 0;
}

.categoryPanel{
    width: 400px;
    margin: 10px 10px;
    position: relative;
    padding-top: 70px;
    padding-left: 3vw;
    padding-right: 3vw;
    padding-bottom: 40px;

    @media screen and(max-width: 1550px) {
        width: 300px;
    }
}

.littleHeader {
	font-family: 'Playfair Display', serif;
    font-weight: 500;
    font-size: 40px;
    text-decoration: none;
}

.image{
    position: absolute;
    left: 0px;
    top: 0px;
}

.arrow{
    opacity: 0.25;
    width: 80px;
    vertical-align: middle;
    transition: 0.1s;
}

.questions{
    margin: 20px 0;
}

.question{
    font-family: futura-pt, sans-serif;
    font-size: 18px;
    line-height: 35px;
    display: block;
    text-decoration: none;
}

.viewMore {
	font-family: 'Playfair Display', serif;
    font-size: 20px;
    vertical-align: top;
    font-weight: 500;
}

.link{
    text-decoration: none;

    &:hover{
        .arrow{
            opacity: 1;
            transition: 0.1s;
        }
    }
}
@import 'styles/colors.scss';

//global
.button {
	border-color: $tyrianPurple;
	border-style: solid;
	border-width: 2px;
	font-family: 'Playfair Display', serif;
	font-weight: normal;
	font-size: 24px;
	height: 75px;
	width: 300px;
	color: $tyrianPurple;
	background-color: transparent;
	cursor: pointer;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			color: $tyrianPurple;
			border-color: rgba(74, 25, 61, 0.15);
		}
	}
}

.image {
	position: absolute;
	top: 70px; left: 2vw;

	@media screen and (max-width: 1450px) {
		left: 12vw;
	}

	@media screen and (max-width: 1150px) {
		left: 5vw;
	}
	
	@media screen and (max-width: 930px) {
		left: 25vw;
	}
	
	@media screen and (max-width: 670px) {
		left: 15vw;
	}
	
	@media screen and (max-width: 520px) {
		left: 8vw;
    }

	svg {
		height: 100px;
	}
}

.serviceContainer {
	//structure inside box
	position: relative;
	display: grid;
	grid-template-rows: 50% 33% 17%;
	min-height: 588px;
	height: 588px;
	text-decoration: none;
	transition: 0.1s;
}

@media (hover: hover) and (pointer: fine) {
	.serviceContainer:hover{
		transition: 0.1s;
		.learnMore {
			display: inline-flex;
		}
		p,
		h2 {
			color: $white;
		}
		svg {
			filter: invert(100%);
		}

		&:hover {
			background-color: $tyrianPurple;
		}
	}
}

.paragraph {
	font-family: futura-pt, sans-serif;

	font-size: 18px;
	line-height: 35px;
	text-align: left;

	width: 280px;
	margin-top: 30px;
	justify-self: center;
}

.discoverParagraph{
	composes: paragraph;
	font-size: 22px;
	line-height: 40px;
}

.littleHeader {
	font-family: 'Playfair Display', serif;
	font-size: 40px;

	width: 280px;
	margin-top: 130px;

	justify-self: center;
}

.learnMore {
	display: inline-flex;
	justify-self: center;
	align-items: center;
	width: 280px;
	margin-top: 20px;
	p {
		width: auto;
		font-family: 'Playfair Display';
		margin-bottom: 30px;
		font-weight: 500;
	}
	svg {
		opacity: 0.1;
		height: 25px;
		margin-left: 30px;
	}
}

.firstMargins{
	height: min-content;
}

//desktop
@media screen and (min-width: 1451px) {
	.last {
		display: none;
	}
	.container {
		display: grid;

		grid-template-columns: 600fr 440fr 440fr 440fr; // should be 600px 440px 440px 440px on 1080p
		grid-template-rows: 50% 50%;
	}
	.first {
		// first is "Discover Our Services"
		grid-row-start: 1;
		grid-row-end: 3;
		display: grid;
		grid-template-rows: 25% 12.5% 12.5% auto;
	}
	.firstMargins {
		width: 300px;
		justify-self: center;
	}
	.learnMore {
		display: none;
	}
	.serviceContainer:nth-child(2n) {
		background-color: $whiteCream;
	}
	.serviceContainer:nth-child(2n + 1) {
		background-color: $cream;
	}
}
//tablet
@media screen and (max-width: 1450px) and (min-width: 931px) {
	.container {
		display: grid;

		grid-template-columns: 50% 50%;
		grid-template-rows: auto 1fr 1fr 1fr auto 100px;
	}
	.first {
		grid-column-start: 1;
		grid-column-end: 3;
		display: grid;
		h2 {
			text-align: center;
			margin-top: 40px;
		}
		p {
			display: none;
		}
		button {
			display: none;
		}
	}
	.last {
		grid-column-start: 1;
		grid-column-end: 3;
		display: grid;
	}
	.lastMargins {
		margin-top: 80px;
		width: 500px;
		justify-self: center;
		align-self: center;
	}
	.button {
		width: 500px;
	}
	.firstMargins {
		width: auto;
		justify-self: center;
		align-self: center;
	}

	.serviceContainer:nth-child(4n + 1) {
		background-color: $whiteCream;
	}
	.serviceContainer:nth-child(4n) {
		background-color: $cream;
	}
	.serviceContainer:nth-child(4n + 2) {
		background-color: $whiteCream;
	}
	.serviceContainer:nth-child(4n + 3) {
		background-color: $cream;
	}
}
//mobile
@media screen and (max-width: 930px) {
	.container {
		display: grid;

		grid-template-columns: 1fr;
		grid-template-rows: auto repeat(6, 1fr) auto 100px;
	}
	.first {
		display: grid;
		//grid-template-rows: auto;
		grid-row-start: 1;
		grid-column-end: 2;
		h2 {
			text-align: center;
			margin-top: 40px;
		}
		p {
			display: none;
		}
		button {
			display: none;
		}
	}
	.last {
		grid-column-start: 1;
		grid-column-end: 2;
		display: grid;
	}
	.lastMargins {
		width: 300px;
		justify-self: center;
		align-self: center;
	}
	.firstMargins {
		width: auto;
		justify-self: center;
		align-self: center;
	}

	.serviceContainer:nth-child(2n) {
		background-color: $whiteCream;
	}
	.serviceContainer:nth-child(2n + 1) {
		background-color: $cream;
	}
}

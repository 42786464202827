@import 'styles/colors.scss';

.headerContainer{
    background-color: $cream;
    padding: 100px 10vw;
    box-sizing: border-box;

    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 1560px) {
        padding: 100px 5vw;
    }
}

.leftSide{
	width: 400px;
	margin-right: 100px;

	@media screen and (max-width: 1560px) {
		width: 300px;
	}

    @media screen and (max-width: 1024px) {
		width: 100%;
		margin-right: 0;
		margin-bottom: 30px;
	}
}

.rightSide{
    margin: auto;
	width: calc(100% - 400px - 100px);

	@media screen and (max-width: 1560px) {
		width: calc(100% - 300px - 100px);
	}

	@media screen and (max-width: 1024px) {
		width: 100%;
	}
}

.fullWidth{
	width: 100%;
}
.sectionHeader{
	font-family: 'Playfair Display', serif;
	font-size: 40px;
	font-weight: 500;

	margin-bottom: 50px;

	@media screen and (max-width: 1024px) {
		text-align: center;
	}
}

.container{
    padding: 20px 8vw;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and(max-width: 1024px) {
        display: none;
    }
}

.logo{
    width: 200px;
}

.links a, .links div {
    margin: 0 2.5vw;
}
@import 'styles/colors.scss';

.cookieImage{
	width: 50px;
	margin-right: 10px;
	margin-left: 5vw;
}

.textContainer{
    display: flex;
    align-items: center;
    color: $tyrianPurple;

    @media (min-width: 600px) and (max-width: 900px) {
  
        margin-right: 250px;
        
      }
}

.link{
    font-family: futura-pt, sans-serif !important;
    color: $tyrianPurple;
    font-size: 14px;
    text-decoration: none;
}

.footerContainer{
    background: #FCFCFB;
    font-family: futura-pt, sans-serif;
    align-items: center;
    
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    left: 0px;
    position: fixed;
    width: 100%;
    z-index: 999;
    bottom: 0px;

    @media only screen and (max-width: 900px) {
        display: block;
    }
}

.footerContainer div{
    margin-left: 0px;
}

.button{
    font-family: futura-pt, sans-serif;
    background: $tyrianPurple;
    border: 0px;
    border-radius: 0px;
    box-shadow: none;
    color: $cream;
    cursor: pointer;
    flex: 0 0 auto;
    padding: 8px 25px;
    margin: 15px;
    font-size: 15px;
    font-weight: 300;
    margin-left: 5vw;
}

.declineButton{
    font-family: futura-pt, sans-serif;
    border: 0px;
    border-radius: 0px;
    box-shadow: none;
    flex: 0 0 auto;
    padding: 8px 25px;
    cursor: pointer;
    margin: 15px;
    font-size: 13px;
    background: $cream;
    color: $tyrianPurple;
    font-size: 15px;
    font-weight: 300;
    margin-right: 280px;

    @media only screen and (max-width: 900px) {
        margin-right: 0px;
    }
}

.content{
    flex: 1 0 300px;
    margin: 15px;
}
@import 'styles/colors.scss';

.carousel {
    margin: 80px 150px;

    @media screen and (max-width: 1560px) {
        margin: 80px 80px;
    }
    
    @media screen and (max-width: 1024px) {
        margin: 80px 10vw 100px 10vw;
    }
}

.slider{
    box-sizing: border-box;
    padding: 5px 15px;

    @media screen and (max-width: 1560px) {
        padding: 5px 10px;
    }

    @media screen and (max-width: 576px) {
        padding: 0;
    }
}

.slide{
    height: 100%;
    box-sizing: border-box;

    @media screen and (max-width: 1024px) {
        padding: 0 50px;
    }
    
    @media screen and (max-width: 576px) {
        padding: 0 10px;
	}
}

.dots li button:before{
    color: $tyrianPurple !important;
    font-size: 12px !important;
}

.arrow{
    width: 20px !important;
    height: auto !important;
}

.link{
    font-family: 'Playfair Display', serif;
    font-size: 23px;
    font-weight: 500;
    text-decoration: none;
    float: right;
    z-index: 5000;
    position:relative;

    &:hover{
        svg{
            opacity: 1;
            transition: 0.1s;
        }
    }

    @media screen and (max-width: 1024px) {
        float: none;
        position:relative;

        bottom: -50px;
        left: 0;
    }
}

.linkArrow{
    display: inline;
    width: auto;
    height: 30px;
    vertical-align: middle;
    opacity: 0.25;
    transition: 0.1s;

    @media screen and (max-width: 1024px) {
        opacity: 1;
    }
}
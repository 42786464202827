.headerTitle{
    font-family: 'Playfair Display', serif;
    font-size: 70px;
    margin: 0;
    font-weight: 500;
    display: inline;

    @media screen and (min-width: 1025px) {
        word-spacing: 100vw;
    }
}

.headerIcon{
    vertical-align: middle;
    margin-left: 20px;
    margin-bottom: 20px;
    width: 140px;
    height: auto;
}

.littleHeader{
    font-family: 'Playfair Display', serif;
    font-size: 40px;
    font-weight: 500;

    @media screen and (max-width: 1024px) {
		text-align: center;
    }
}

.paragraph{
    font-family: futura-pt, sans-serif;
    font-size: 18px;
    line-height: 35px;
}

.titleInline{
    display: inline-block;
}
@use "sass:math";
@import "styles/colors.scss";

.paragraph {
  font-family: futura-pt, sans-serif;
  font-size: 20px;
  text-align: justify;
}

.littleHeader {
  font-family: "Playfair Display", serif;
  font-size: 32px;
}

.blogPostContainer {
  margin: 60px 60px;
  @media screen and (max-width: 1024px) {
    margin: 40px;
  }
  @media screen and (max-width: 576px) {
    margin: 0px 5px;
  }
}

.hover {
  padding: 1px 0;
  height: 100%;

  position: relative;
}

@media (hover: hover) and (pointer: fine) {
  .hover:hover {
    background-color: $cream;
    transition: 0.1s;

    .blogPostContainer {
      .readMore {
        opacity: 1;
        transition: 0.1s;
      }
    }
  }
}

.link {
  text-decoration: none;
}

.readMore {
  opacity: 0;
  transition: 0.1s;

  font-family: "Playfair Display", serif;
  font-size: 23px;
  font-weight: 500;
  text-decoration: none;

  position: absolute;
  bottom: 20px;
  left: 60px;
}

.readMoreArrow {
  display: inline;
  width: auto;
  height: 30px;
  vertical-align: middle;
  opacity: 0.25;
}

// https://css-tricks.com/aspect-ratio-boxes/
.image {
  height: 0;
  overflow: hidden;
  padding-top: math.div(247px, 418px) * 100%;
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

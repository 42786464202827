@import 'styles/colors.scss';

.headerTitle{
    font-family: 'Playfair Display', serif;
    font-size: 70px;
    font-weight: 500;
    margin: 0;
    display: inline;
}

.headerIcon{
    vertical-align: middle;
    margin-left: 20px;
    margin-bottom: 20px;
}
.p{
    font-family: futura-pt, sans-serif;
    font-size: 18px;
    line-height: 35px;
    text-align: justify;
}

.h1{
    font-family: 'Playfair Display', serif;
    font-size: 40px;
    font-weight: 500;
}

.a{
    composes: p;
    text-decoration: none;
    font-weight: 500;
}

.ul{
    list-style-type: none;
    padding: 0;
}

.li{
    composes: p;
}

.li:before{
    content: "- ";
}
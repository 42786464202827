@import 'styles/colors.scss';

.link{
	margin-top: 10px;
	display: flex;
	align-items: center;
	height: 85px;

	word-break: break-all;
}

.link a{
	text-decoration: none;
}

.text{
	font-family: 'Playfair Display', serif;
	font-size: 20px;
	font-weight: 500;
}

.icon{
	width: 35px;
	min-width: 35px;
	height: auto;
	opacity: 0.25;
	margin-right: 20px;
}

.number{
	composes: text;
	font-size: 25px;
	font-weight: 500;
}